import { Enterprise } from './types';
import { APICore } from './apiCore';

const api = new APICore();
const baseUrl = "/api/v1"

export function entPath(path: string, enterpriseId?: number): string {
    return enterpriseId ? `${baseUrl}/enterprise/${enterpriseId}/${path}` : `${baseUrl}/${path}`;
}

export function listAdsets(enterpriseId?: number, page?: number, pageSize?: number, q?: string,) {
    let filters = [];
    if (q) {
        filters.push(JSON.stringify({ "ID": "Q", "Value": q }))
    }
    const params = {
        'page.page': page || 0,
        'page.pageSize': pageSize || 0,
        filters
    }
    return api.get(entPath("adsets", enterpriseId), params);
}

export function getAdset(id: number | string) {
    return api.get(`${baseUrl}/adsets/${id}`, {});
}

export function putAdset(id: number | string, data: any) {
    return api.update(`${baseUrl}/adsets/${id}`, data);
}

export function changeAdsetState(id: number | string, state: string) {
    return api.update(`${baseUrl}/adsets/${id}/state`, {
        status: state
    });
}

export function listAdsetTracks(id: number | string) {
    return api.get(`${baseUrl}/adsets/${id}/tracks`, {});
}

export function listAdsetTimeline(id: number | string, startTime: number, endTime?: number, params: {
    'page.page'?: number,
    'page.pageSize'?: number,
    onlyDones?: boolean
} = {},) {
    return api.create(`${baseUrl}/adsets/${id}/timeline`, {
        page: {
            page: params['page.page'] || 0,
            pageSize: params['page.pageSize'] || 100,
        },
        onlyDones: params.onlyDones || false,
        startTime,
        endTime,
    });
}

export function ackPush(adsetId: number | string, count: number) {
    return api.update(`${baseUrl}/adsets/${adsetId}/ack`, { count });
}

export function relenishPush(adsetId: number | string, count?: number, clickId?: string) {
    return api.update(`${baseUrl}/adsets/${adsetId}/relenish`, { count, clickId });
}

export function createAdset(data: any) {
    return api.create(`${baseUrl}/adsets`, data);
}

export function createEnterpriseAdset(enterpriseId: number, data: any) {
    return api.create(entPath('adsets', enterpriseId), data)
}

export function deleteAdset(adsetId: number | string) {
    return api.delete(`${baseUrl}/adsets/${adsetId}`)
}

export function moveAdset(adsetId: number | string, data: any) {
    return api.update(`${baseUrl}/adsets/${adsetId}/move`, data)
}