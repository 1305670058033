import { Page } from 'redux/common';
import { TrackActionTypes } from './constants';

export type TrackActionType = {
    type:
    | TrackActionTypes.API_RESPONSE_SUCCESS
    | TrackActionTypes.API_RESPONSE_ERROR
    | TrackActionTypes.LIST_ADSET_TRACKS
    | TrackActionTypes.LIST_ADSET_TIMELINE
    | TrackActionTypes.RESET
    payload: {} | string;
};

export interface Track {
    id: string
    adsetId: number
    trackid: string
    createdAt: string
    eventType: string
    clickId: string
    ip: string
    params: TrackParams
}

export interface TrackParams {
    staytime: number
    scrollPercent: number
}


export const trackApiResponseSuccess = (actionType: string, data: Track | {}, page?: Page): TrackActionType => ({
    type: TrackActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data, page },
});

export const trackApiResponseError = (actionType: string, data: Track | {}): TrackActionType => ({
    type: TrackActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, data },
});

export const listAdsetTracks = (adsetId: number | string): TrackActionType => ({
    type: TrackActionTypes.LIST_ADSET_TRACKS,
    payload: {
        id: adsetId
    },
})

export const listTimeline = (adsetId: number | string, startTime: number, endTime?: number, onlyDones?: boolean, page?: number, pageSize?: number): TrackActionType => ({
    type: TrackActionTypes.LIST_ADSET_TIMELINE,
    payload: {
        id: adsetId,
        startTime,
        endTime,
        onlyDones,
        page,
        pageSize
    },
})

export const resetTrack = (): TrackActionType => ({
    type: TrackActionTypes.RESET,
    payload: {},
})