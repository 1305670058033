import { listAdsetTracks as listAdsetTracksApi, listAdsetTimeline as listAdsetTimelineApi } from 'helpers';
import { SagaIterator } from 'redux-saga';
import { all, fork, call, takeEvery, put } from 'redux-saga/effects';
import { trackApiResponseSuccess, trackApiResponseError } from './actions';
import { TrackActionTypes } from './constants';

type TrackPayload = {
    payload: {
        id?: number | string,
        startTime?: number,
        endTime?: number,
        onlyDones?: boolean,
        page?: number,
        pageSize?: number
    };
    type: string;
};

function* listAdsetTracks({ payload: { id }, type }: TrackPayload): SagaIterator {
    try {
        const response = yield call(listAdsetTracksApi, id!);
        const { tracks } = response.data;
        yield put(trackApiResponseSuccess(TrackActionTypes.LIST_ADSET_TRACKS, tracks))
    } catch (error: any) {
        yield put(trackApiResponseError(TrackActionTypes.LIST_ADSET_TRACKS, error));
    }
}

function* listAdsetTimelines({ payload: { id, startTime, endTime, page = 0, pageSize, onlyDones }, type }: TrackPayload): SagaIterator {
    try {
        const response = yield call(listAdsetTimelineApi, id!, startTime!, endTime, {
            'page.page': page,
            'page.pageSize': pageSize,
            onlyDones
        });
        const { timeline, page: { totalPages } } = response.data;
        yield put(trackApiResponseSuccess(TrackActionTypes.LIST_ADSET_TIMELINE, timeline, {
            pageCount: totalPages
        }))
    } catch (error: any) {
        yield put(trackApiResponseError(TrackActionTypes.LIST_ADSET_TIMELINE, error));
    }
}

function* watchListAdsetTracks() {
    yield takeEvery(TrackActionTypes.LIST_ADSET_TRACKS, listAdsetTracks);
}

function* watchlistAdsetTimelines() {
    yield takeEvery(TrackActionTypes.LIST_ADSET_TIMELINE, listAdsetTimelines);
}


function* trackSaga() {
    yield all([fork(watchListAdsetTracks), fork(watchlistAdsetTimelines)])
}

export default trackSaga;